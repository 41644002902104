<template>
  <v-data-table
    :headers="headers"
    :items="$store.state.totens.data"
    sort-by="calories"
    class="elevation-1"
    :loading="loading"
    loading-text="Carregando..."
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Totens</v-toolbar-title>

        <v-divider class="mx-4" inset vertical></v-divider>

        <v-spacer></v-spacer>

        <tooltip></tooltip>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card :loading="loadingDelete">
            <v-card-title class="headline">Excluir este Totem {{$store.state.totens.editedTotem.totemId}}?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1"  text @click="dialogDelete = false"
                >Cancelar</v-btn
              >
              <v-btn color="blue darken-1" :loading="loadingDelete" text @click="deleteItemConfirm"
                >Sim</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.debug="{ item }">
      <SwitchDebug color="primary" :item="item" />
    </template>

    <template v-slot:item.status="{ item }">
      <v-avatar
        :color="item.status ? 'green' : 'red'"
        size="20"
        :title="item.status ? 'online' : 'offline'"
      >
      </v-avatar>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>

    <template v-slot:no-data=""> Nenhum totem cadastrado </template>

  </v-data-table>
</template>

<script>
import Tooltip from "./tooltip";
import lodash from "lodash";
import {getTotens, deleteTotem} from '../../services/totens'
import SwitchDebug from './SwitchDebug.vue'
export default {
  components: {
    Tooltip,
    SwitchDebug
  },
  data: () => ({
    loading: false,
    loadingDelete:false,
    dialogDelete: false,
    headers: [
      {
        text: "Nome",
        align: "start",
        value: "name",
      },
      {
        text: "Totem ID",
        align: "start",
        value: "totemId",
      },
      {
        text: "Modo Debug",
        align: "start",
        value: "debug",
      },
      { text: "Status", value: "status" },
      { text: "Região", value: "region" },
      { text: "Usuário", value: "user.name" },
      { text: "Playlist", value: "playlist._id" },
      { text: "Actions", value: "actions", sortable: false },
    ]
  }),

  methods: {
    
    lodash() {
      return lodash;
    },

    editItem(item) {
        this.$store.commit('editTotem', item)    
    },

    deleteItem(item) {
      this.$store.commit('prepareTotemToDelete', item) 
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loadingDelete = true
     deleteTotem().then(()=>{
       this.$store.commit('success', 'Totem deletado com sucesso')
       this.loadingDelete = false
       this.dialogDelete = false;
       const index = lodash.findIndex(this.$store.state.totens.data, {_id: this.$store.state.totens.editedTotem._id})
       this.$store.state.totens.data.splice(index, 1)
     })
     .catch(err=>{
       this.$store.commit('failed', 'Falha ao deletar este totem: '+err)
       this.loadingDelete = true
     })
      
    },

  },
  async mounted(){
    this.loading = true
    await getTotens().catch(()=>{})
    this.loading = false
  }
};
</script>