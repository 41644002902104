<template>
  <v-switch
    color="primary"
    :loading="loadding"
    v-model="item.debug"
    
    @change="saveDebugMode"
  ></v-switch>
</template>

<script>
import {debugMode} from '../../services/rasp'
export default {
  props: ["item"],
  data: () => ({
    loadding: false,
  }),
  methods: {
    saveDebugMode() {
        this.loadding = true
      this.$store.commit("setEditedTotem", this.item);
      debugMode()
        .then(() => {
        
          this.$store.commit("success", "Totem alterado com sucesso");
          this.loadding = false
        })
        .catch((err) => {
            this.loadding = false
            this.item.debug = false
          this.$store.commit(
            "failed",
            "Falha ao tentar salvar este totem, " + err
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>