import axios from 'axios'
import baseUrl from './baseUrl'
import store from '../store'
const errors = err => {
  console.error(err.response ? err.response.data.stack : err.stack)
  return Promise.reject(err.response ? err.response.data.message:'Houve uma falha na comunição, verifique sua internet e tente novamente')
}
const headers = {token: localStorage.getItem('token')}
const totens = axios.create({ baseURL: baseUrl.totem, headers })

export const debugMode = () => totens.put('/debug-mode/'+store.state.totens.editedTotem._id, {debug: store.state.totens.editedTotem.debug, totemId: store.state.totens.editedTotem.totemId}).then(({data}) =>  data)
.catch(errors)